import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';

import logo from 'img/companyLogo/mixpanel.svg';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Mixpanel?</SubTitle>
      <p>
        Mixpanel is a tool that collects data to help you convert, engage, and retain more users.
        Connecting it to LiveSession allows you to:
      </p>
      <ul>
        <li>Access all user data in one place</li>
        <li>Use direct links to session replays</li>
        <li>Gain a deeper understanding of user behaviour</li>
      </ul>
      <SubTitle className="mt-5">How does it work?</SubTitle>
      <p>
        LiveSession adds a new label in Mixpanel. The label is called <i>sessionURL</i> and it
        contains a link to the session recording. With this link, you can access the session
        straight from the Mixpanel dashboard.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy lang="js">
        {`<script>
__ls("getSessionURL", function (url, isNewSession) {
    mixpanel.track("SessionURL", { sessionURL: url });
});
</script>
`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration works you need to have both Mixpanel and LiveSession installed on
        your website. If you haven&apos;t installed our tracking code yet check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Mixpanel integration',
  canonical: '/help/mixpanel-integration/',
  metaDescription: 'Add LiveSession recordings to event details in Mixpanel.',
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
